import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

const EducationFourth = () => {
  return (
    <>
      <h1 className="title">Расчёт операционных затрат (OPEX)</h1>
      <p className="text">
        <b>OPEX</b> (OPeration EXpense) - постоянные расходы на обеспечение
        работы компании. Например, оплата аренды, коммунальные платежи, расходы
        на закупку сырья.
      </p>
      <p className="text">
        Для расчёта OPEX все данные операционные затраты нужно перевести в
        годовой формат и сложить. В различных ситуациях может менятся и формат,
        например в краткосрочных нишах ОРЕХ может рассчитываться в
        месяцах/кварталах/неделях.
      </p>
      <p className="text">Просчитаем OPEX для всех линий производства:</p>
      <div className="page__list">
        <div className="page__list-item">
          <h2>Китайская</h2>
          <p className="text">
            Операционные затраты:
            <ul>
              <li>
                Электричество. В соответствии с технологией, тепличный комплекс
                будет потреблять 4 млн КВт*час/год. Стоимость 1 КВт*час
                составляет 3 рубля.
              </li>
              <li>
                Оплата труда. Стоимость всех трудовых ресурсов составляет 500
                тыс. руб./мес.
              </li>
              <li>
                Прочие затраты (удобрения, семена и др) составляют 160 тыс.
                руб./мес.
              </li>
            </ul>
          </p>
          <br />
          <p className="text">
            Переведем их в годовой формат:
            <ul>
              <li>
                Электричество:{" "}
                <strong>
                  4 млн. КВт*час/год * 3 руб. = 12 млн. руб. / год.
                </strong>
              </li>
              <li>
                Оплата труда:{" "}
                <strong>
                  500 тыс. руб./мес. * 12 мес. = 6 млн. руб. / год.
                </strong>
              </li>
              <li>
                Прочие затраты:{" "}
                <strong>
                  160 тыс. руб./мес. * 12 мес. = 1,92 млн. руб. / год.
                </strong>
              </li>
            </ul>
          </p>
          <br />
          <p className="text">
            Так как оставшийся товар мы перевозим на материк, а перевозка не
            бесплатная, то нужно также учитывать цену перевозок с острова на
            материк:
            <ul>
              <li>
                Логистика:{" "}
                <strong>360 000 кг. * 33 руб. / кг. = 11,8 млн. руб.</strong>
              </li>
            </ul>
          </p>
          <h3>Итого: 31,8 млн. руб. в год</h3>
        </div>
        <div className="page__list-item">
          <h2>Голландская</h2>
          <p className="text">
            Операционные затраты:
            <ul>
              <li>
                Электричество. В соответствии с технологией, тепличный комплекс
                будет потреблять 8 млн КВт*час/год. Стоимость 1 КВт*час
                составляет 3 рубля.
              </li>
              <li>
                Оплата труда. Стоимость всех трудовых ресурсов составляет 1 млн.
                руб./мес.
              </li>
              <li>
                Прочие затраты (удобрения, семена и др) составляют 300 тыс.
                руб./мес.
              </li>
            </ul>
          </p>
          <br />
          <p className="text">
            Переведем их в годовой формат:
            <ul>
              <li>
                Электричество:{" "}
                <strong>
                  8 млн. КВт*час/год * 3 руб. = 24 млн. руб. / год.
                </strong>
              </li>
              <li>
                Оплата труда:{" "}
                <strong>
                  1 млн. руб./мес. * 12 мес. = 12 млн. руб. / год.
                </strong>
              </li>
              <li>
                Прочие затраты:{" "}
                <strong>
                  300 тыс. руб./мес. * 12 мес. = 3,6 млн. руб. / год.
                </strong>
              </li>
            </ul>
          </p>
          <br />
          <p className="text">
            Логистика:{" "}
            <strong>2 400 000 кг. * 33 руб. / кг. = 79,2 млн. руб.</strong>
          </p>
          <h3>Итого: 118,8 млн. руб. в год</h3>
        </div>
        <div className="page__list-item">
          <h2>Японская</h2>
          <p className="text">
            Операционные затраты:
            <ul>
              <li>
                Электричество. В соответствии с технологией, тепличный комплекс
                будет потреблять 5 млн КВт*час/год. Стоимость 1 КВт*час
                составляет 3 рубля.
              </li>
              <li>
                Оплата труда. Стоимость всех трудовых ресурсов составляет 650
                тыс. руб./мес.
              </li>
              <li>
                Прочие затраты (удобрения, семена и др) составляют 240 тыс.
                руб./мес.
              </li>
            </ul>
          </p>
          <br />
          <p className="text">
            Переведем их в годовой формат:
            <ul>
              <li>
                Электричество:{" "}
                <strong>
                  5 млн. КВт*час/год * 3 руб. = 15 млн. руб. / год.
                </strong>
              </li>
              <li>
                Оплата труда:{" "}
                <strong>
                  650 тыс. руб./мес. * 12 мес. = 7,8 млн. руб. / год.
                </strong>
              </li>
              <li>
                Прочие затраты:{" "}
                <strong>
                  240 тыс. руб./мес. * 12 мес. = 2,88 млн. руб. / год.
                </strong>
              </li>
            </ul>
          </p>
          <br />
          <p className="text">
            Логистика:{" "}
            <strong>240 000 кг. * 33 руб. / кг. = 7,92 млн. руб.</strong>
          </p>
          <h3> Итого: 33,6 млн. руб. в год</h3>
        </div>
      </div>
      <div className="page__footer">
        <button className="button_outlined" onClick={() => AppStore.goBack()}>
          Назад
        </button>
        <button className="button" onClick={() => AppStore.navigate(6)}>
          Далее
        </button>
      </div>
    </>
  );
};

export default observer(EducationFourth);
